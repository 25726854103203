var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('header', {
    staticClass: "mb-2"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('section', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('p', [_c('strong', [_vm._v("No. Invoice")])]), _c('p', [_vm._v(_vm._s(_vm.pb.no_invoice))])]), _vm.isAdminGudang && _vm.pb.status == 1 ? _c('div', [_c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.batal();
      }
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Batalkan Transaksi")]), _c('feather-icon', {
    staticClass: "ml-1",
    attrs: {
      "icon": "ChevronRightIcon"
    }
  })], 1)], 1) : _vm._e(), _vm.isAdminGudang && _vm.pb.status == 0 ? _c('div', [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push("/owner/pengiriman/edit/".concat(_vm.$route.params.id));
      }
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Edit Pengiriman")]), _c('feather-icon', {
    staticClass: "ml-1",
    attrs: {
      "icon": "Edit2Icon"
    }
  })], 1)], 1) : _vm._e()])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal Pengiriman")])]), _c('p', [_vm._v(_vm._s(_vm.humanDate(_vm.pb.tanggal)))])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Gudang Tujuan")])]), _c('p', [_vm._v(_vm._s(_vm.pb.to ? _vm.pb.to.nama_gudang : "-"))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _vm.pb.status == 1 ? _c('p', [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s(_vm.pb.status_ket.toUpperCase()))])], 1) : _vm._e(), _vm.pb.status == 0 ? _c('p', [_c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(_vm._s(_vm.pb.status_ket.toUpperCase()))])], 1) : _vm._e()])], 1)], 1)], 1), _c('main', [_c('b-card', [_c('h5', [_vm._v("Daftar Barang Yang Diterima")]), _c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "items": _vm.barangs,
      "fields": _vm.barangFields
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.qty)) + " ")];
      }
    }, {
      key: "cell(approve)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm.isCabang && item.diterima == 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Terima',
            expression: "'Terima'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "rounded": "",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.setuju(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CheckIcon"
          }
        }), _vm._v(" Diterima ")], 1) : _vm._e(), _vm.isCabang && item.diterima == 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Tolak',
            expression: "'Tolak'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "rounded": "",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tolak(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        }), _vm._v(" Ditolak ")], 1) : _vm._e(), _vm.isAdminGudang && item.diterima == 2 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Kembalikan barang ke penyimpanan',
            expression: "'Kembalikan barang ke penyimpanan'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "rounded": "",
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.kembalikan(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "RefreshIcon"
          }
        }), _vm._v(" Kembalikan Barang ke Penyimpanan ")], 1) : _vm._e(), item.diterima > 0 ? [_vm._v(" " + _vm._s(item ? item.status.toUpperCase() : "-") + " ")] : _vm._e()];
      }
    }])
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }